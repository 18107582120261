import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import '../styles/notFound.scss';

const NotFound = () => {
    return (
        <>
            <NavBar />
            <div id='container-404'>
                <div id='content-404'>
                    <h1 id='title-404'>404 Not Found</h1>
                    <p id='desc-404'>
                        The page you are looking for might be in another universe 🌌
                    </p>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default NotFound;