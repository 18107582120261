import React from 'react'
import Modal from './Modal'
import '../styles/fullscreen.scss'

class Thumbnail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: this.props.data.title,
            imgUrl: this.props.data.url,
            modals: []
        }
    }

    expand = () => {
        console.log('expand')
        const newModals = [
            ...this.state.modals,
            <Modal
                key={this.state.modals.length}
                data={this.props.data}
            />
        ];
        console.log(newModals)
        this.setState({ modals: newModals });
    };

    render() {
        const { title, imgUrl } = this.state;
        return (
            <div className="column">
                {this.state.modals}
                <div className="thumb-container">
                    <img src={imgUrl} alt={title} className="thumb-image" onClick={this.expand}></img>
                    <div className="middle">
                        <div className="middle-text" onClick={this.expand}>{title}</div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Thumbnail