import React from 'react'
import '../styles/footer.scss';

const Footer = () => {
    return (
        <footer id='footer'>
            <p>&copy; 2024 Liam Erickson. All rights reserved.</p>
        </footer>
    );
}

export default Footer;