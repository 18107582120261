import React, { useState } from 'react';
import '../styles/navBar.scss';

const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <a className="mobile-burger" onClick={toggleMenu}>
                <span></span>
            </a>
            <ul className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
                <li><a href="../../" className="menu-item">Home</a></li>
                <li><a href="../about/" className="menu-item">About</a></li>
                <li><a href="../art/" className="menu-item">Art</a></li>
                <li><a href="../code/" className="menu-item">Code</a></li>
                <li><a href="../contact/" className="menu-item">Contact</a></li>
            </ul>
        </>
    );
}

export default NavBar;