import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Button } from 'react-bootstrap';
import Data from '../components/Data.js';
import '../styles/admin.scss';

class Admin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            imgUrl: '',
            KeyProps: Data[0],
            isLoggedIn: false,
            modals: []
        }
        this.addArtShowcase = this.addArtShowcase.bind(this);
        this.addCodeShowcase = this.addCodeShowcase.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    login = () => {
        // Add login functionality here
        this.setState({ isLoggedIn: true });
    }


    isValidImageUrl(url) {
        // Regular expression for common image URL patterns
        const imgPattern = /\.(jpeg|jpg|gif|png)$/;

        // Test if the URL matches the pattern
        return imgPattern.test(url);
    }

    addArt = (index) => {
        if (this.isValidImageUrl(this.state.imgUrl)) {
            console.log(this.state.title, this.state.imgUrl)
            console.log('hi', index)
            console.log(Data[0].art.items[index].images)
            Data[0].art.items[index].images.push({
                title: this.state.title,
                imgURL: this.state.imgUrl
            });
        } else {
            console.log('invalid')
            return;
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    addArtShowcase = () => {
        const artData = this.state.KeyProps.art.items;
        const newModals = [
            ...this.state.modals,
            <div key={this.state.modals.length} className="admin-showcase">
                <input type="text" placeholder="Title" className='image-input' id='title' onChange={this.handleChange} />
                <input type="text" placeholder="Image URL" className='image-input' id='imgUrl' onChange={this.handleChange} />
                <h2>Which project would you like to add to?</h2>
                {artData.map((item, index) => {
                    const title = item.title.split(' ');
                    return (
                        <Button key={item.id} className={item.id} onClick={() => this.addArt(index)}>{title[0]}</Button>
                    )
                })}
                <Button variant='danger' onClick={() => this.setState({ modals: [] })}>Cancel</Button>
            </div>
        ];
        this.setState({ modals: newModals });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        alert("The browser will not reload when the alert box is closed.");
    };

    addCodeShowcase = () => {
        const codeData = this.state.KeyProps.art.items;
        const newModals = [
            ...this.state.modals,
            <div className="admin-showcase">
                {/* Add HTML for modular addition here*/}
            </div>
        ];
        console.log(newModals)
        this.setState({ modals: newModals });
    }

    render() {
        const isLoggedIn = this.state.isLoggedIn
        return (
            <>
                <NavBar />
                <div className="intro">
                    <h1>Admin View</h1>
                </div>
                <div className={`login ${isLoggedIn ? 'loggedIn' : 'loggedOut'}`} id='login'>
                    <input type="text" placeholder="Username" className='login-input' />
                    <br />
                    <input type="password" placeholder="Password" className='login-input' />
                    <br />
                    <Button type="submit" onClick={this.login}>Submit</Button>
                    <br />
                </div>
                <div className={`admin-modal ${isLoggedIn ? 'loggedIn' : 'loggedOut'}`}>
                    <Button className="adminButton" onClick={this.addArtShowcase}>Add Art</Button>
                    <Button className="adminButton" onClick={this.addArtShowcase}>Add Project</Button>
                    {this.state.modals}
                </div>
                <Footer />
            </>
        );
    }
}
export default Admin;

