import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from '../pages/home';
import About from '../pages/about';
import Art from '../pages/art';
import Code from '../pages/code';
import Contact from '../pages/contact';
import Admin from '../pages/admin';
import NotFound from '../pages/NotFound';

const AppRouter = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/art" element={<Art />} />
                    <Route path="/code" element={<Code />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default AppRouter;