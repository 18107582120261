import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import data from '../components/Data.js'
import Thumbnail from '../components/Thumbnail'
import '../styles/artNcode.scss'

class Code extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            KeyProps: data[0]
        }
    }
    render() {
        return (
            <>
                <NavBar />
                <div className="intro">
                    <h1>Code</h1>
                    <p className="description">
                        I have experience coding in many languages such as Javascript, Java, R, GoLang, and Python. I love implementing
                        my artwork with code as often as I can, which lead me to create staple project such as the Random Character Generator.
                        Currently, I am learning how to code on the blockchain and create smart contracts using Solidity.
                    </p>

                    <p className="groupInst">
                        Hover over and click on a group to learn more.
                    </p>
                    <hr className="desktopHr" />
                </div>

                <div className="row">
                    {this.state.KeyProps.code.items.map((item, index) => {
                        return (
                            <Thumbnail
                                key={index}
                                data={item}
                            />
                        )
                    })}
                </div>
                <div id='github-text'>
                    See my other projects on <a link href="https://github.com/ericksonl">GitHub</a>.
                </div>
                <Footer />
            </>
        )
    }
}
export default Code