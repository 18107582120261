import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const Home = () => {
    return (
        <>
            <NavBar />
            <div className='home-showcase'>
                <div className="home-container showcase-inner">
                    <h1 id='h1-title'>Liam Erickson</h1>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Home;